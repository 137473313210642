import {useTranslation} from "react-i18next";
import React from "react";

function PrivacyPolicy() {
    // eslint-disable-next-line
    const {t} = useTranslation();
    return (<>
        <main className="site-main">
            <div className="site-banner faq-banner">
                <div className="site-banner-thumb">
                    <img src="/images/faq.png" alt="Privacy policy"/>
                </div>
                <div className="site-banner-wrap">
                    <div className="container">
                        <div className="site-banner-content">
                            <h1>{t("privacy_policy")}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="site-section faq-section pt-0 pt-xl-4">
                <div className="container">
                    <h3 className="text-center">{t("policy")}</h3>

                    <div>
                        <p>
                            <strong>SEKRETESSPOLICY</strong></p>

                        <p>Avsikten med denna sekretesspolicy är att
                        informera om hur och varför Osagian AB (Osagian) samlar in och behandlar personuppgifter.
                        Det är
                        VD för Osagian AB som är ytterst ansvarig för bearbetningen av den information som samlas in
                        och
                        behandlas av Osagian. Ansvaret kan delegeras till en annan position i det dagliga arbetet.
                        Sekretesspolicyn som följer nedan innehåller den informationen som dataskyddsförordningen kräver
                        att
                        vi tillhandahåller i samband med vår behandling av dina personuppgifter. Osagian AB och
                        behandling
                        av personuppgifter överensstämmer med gällande regler för skydd av sådana uppgifter.</p>


                        <p><strong>RÄTTSLIG GRUND FÖR BEHANDLING AV PERSONUPPGIFTER</strong></p>

                        <p>Behandling av personuppgifter är inte tillåten om det inte finns giltiga skäl. Sådana skäl
                            kan
                            till exempel vara samtycke från den registrerade personen, avtal (ett ingånget avtal) eller
                            att
                            vi som ansvariga för behandlingen av personuppgifterna har ett berättigat intresse som har
                            större tyngd än den registrerades krav på skydd av privatlivet. Vårt skäl till
                            databehandlingen
                            är normalt sett att det finns ett avtal och i vissa fall att den registrerade lämnat
                            samtycke.
                            När behandlingen av dina personuppgifter inleds lämnar vi alltid information om skälet till
                            databehandlingen. Om Osagian ska behandla personuppgifter för barn under 16 år krävs
                            vårdnadshavarnas samtycke.</p>


                        <p><strong>Osagians ANVÄNDNING AV PERSONUPPGIFTER</strong></p>

                        <p>Vi samlar in och behandlar personuppgifter för att vi
                        ska kunna uppfylla vårt avtal med våra anställda (administration av
                        anställningsförhållandet)
                        och kunder (administration av kundrelationen) men också för att kunna ge god service och
                        informera om våra produkter och tjänster (marknadsaktiviteter). Användningen av
                        personuppgifter
                        i samband med marknadsaktiviteter bygger på samtycke som du kan styra. Vårt bokningssystem
                        kräver att behöva hantera personuppgifter, en förutsättning att kunna boka via Osagians
                        bokningssystem är samtycke till Osagians hantering av personuppgifter. De uppgifter vi
                        registrerar är namn, födelsedatum, telefonnummer, e-postadress, postadress eventuella
                        kommentarer.</p>


                        <p><strong>UTLÄMNING AV PERSONUPPGIFTER – ANVÄNDNING AV
                        TREDJE PART</strong></p>

                        <p>Registrerade personuppgifter behandlas konfidentiellt hososs, och endast de som behöver informationen i sitt arbete kommer att ha tillgång till den.
                        Som
                        regel delar vi därför inte personuppgifter med någon utanför Osagian. Däremot kan det i
                        vissa
                        sammanhang hända att vi delar med oss av personuppgifter till våra partners, som Facebook
                        eller
                        Google. Delning av personuppgifter med tredje part är ett fall av personuppgiftsbehandling
                        som
                        kräver att vissa juridiska förutsättningar är uppfyllda. Dessa förutsättningar kommer i
                        första
                        hand att utgöras av den registrerade personens samtycke, så du som registrerad kommer alltid
                        att
                        känna till vår eventuella utlämning av uppgifter. Observera att vi under vissa
                        omständigheter är
                        skyldiga att lämna ut dina personuppgifter.</p>

                        <p><strong>COOKIES</strong></p><p>Vi använder cookies på vår hemsida. En cookie är en liten textfil, och vår
                        hemsida
                        kommer alltid att be din webbläsare om att lagra cookien på din dator. Vi gör detta för att
                        komma ihåg vad har gjort på hemsidan, vad du tycker om och hur ofta du har varit på vår
                        hemsida.
                        Det gör det möjligt för oss att ge dig bättre tillgång till olika funktioner. Dessutom
                        använder
                        vi cookies på våra sidor för att föra statistik om vad som beställs och hur mycket vår
                        webbplats
                        används i allmänhet. Det finns två typer av cookies: Den ena typen är en fil som sparas på
                        din
                        dator under en längre tid. Den andra typen är en tillfällig cookie (sessions-cookie). Sådana
                        cookies är bara i bruk under den tid du använder webbplatsen. Vi använder båda typerna av
                        cookies. Du kan ta bort våra cookies genom att öppna webbläsarens inställningar och radera
                        innehåll.</p><p><strong>LAGRING OCH RADERING AV
                        PERSONUPPGIFTERNA</strong>
                    </p>

                        <p>Vi lämnar inte ut dina personuppgifter till andra om det inte finns rättslig
                        grund
                        för det. Exempel på sådana skäl är normalt att du har ingått ett avtal med oss eller att det
                        finns juridiska skäl som tvingar oss till det. All behandling av personuppgifter vi gör sker
                        inom EU/EES-området. Osagian raderar dina personuppgifter utan obefogat dröjsmål och
                        senast
                        efter 6 månader när uppgifterna inte längre är nödvändiga för det syfte de samlades in. De
                        raderas också om du drar tillbaka det samtycke som ligger till grund för behandlingen av
                        uppgifterna eller om du motsätter dig behandlingen och det inte finns några tungt vägande
                        berättigade skäl för behandlingen. Vi har tydliga raderingsrutiner för varje
                        databehandlingsaktivitet. Om du återkallar ditt samtycke till behandling av personuppgifter
                        upphör vår behandling av dina personuppgifter, men detta påverkar inte lagligheten hos en
                        databehandling som baserats på ditt samtycke och som skett innan ditt samtycke
                        återkallades.</p>

                        <p><strong>DINA RÄTTIGHETER</strong></p><p>Du har rätt att
                        begära
                        insyn, rättelse, radering (om inte vi är skyldiga att spara informationen), begränsning av
                        databehandlingen och utlämning av dina personuppgifter (dataportabilitet). Dessutom kan du
                        begära att vi avslutar behandlingen av dina personuppgifter (till exempel genom att dra
                        tillbaka
                        ditt samtycke). Om du vill använda dig av en eller flera av dessa rättigheter, kommer vi att
                        rätta oss efter din begäran inom en månad efter att vi fått den, förutsatt att vi inte är
                        skyldiga att handla på annat sätt. Eventuella förfrågningar kan skickas till vår
                        e-postadress. Om du anser att vi har brutit mot reglerna för behandling av
                        personuppgifter
                        kan du klaga hos Datainspektionen.</p><p>Kontakta oss gärna på vår e-postadress,
                        supportse@osagian.com, om du har kommentarer eller vill ha mer information om vår
                        behandling
                        av dina personuppgifter.</p>
                    </div>
                </div>
            </section>
        </main>
    </>
);
}

export default PrivacyPolicy;
import CryptoJS from 'crypto-js';
import {DECRYPT_iv, DECRYPT_KEY} from "../configs/AppConfig";

export const encryptData = (data) => {
    let jsonData = JSON.stringify(data);
    let key = DECRYPT_KEY;
    let iv = DECRYPT_iv;
    const cipher = CryptoJS.AES.encrypt(jsonData, CryptoJS.enc.Utf8.parse(key)
        , {
            iv: CryptoJS.enc.Utf8.parse(iv), // parse the IV
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
    return cipher.toString();
};


export const decryptData = (data) => {
    let key = DECRYPT_KEY;
    let iv = DECRYPT_iv;

    const cipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key)
        , {
            iv: CryptoJS.enc.Utf8.parse(iv), // parse the IV
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });

    if (cipher) {
        return JSON.parse(cipher.toString(CryptoJS.enc.Utf8));
    } else {
        return {};
    }
};
import React from "react";
import {useTranslation} from "react-i18next";

function PageNotFound() {
    // eslint-disable-next-line
    const {t} = useTranslation();
    return (<>
        <main className="site-main">
            <div className="site-banner faq-banner">
                <div className="site-banner-thumb">
                    <img src="/images/faq.png" alt="Faq"/>
                </div>
                <div className="site-banner-wrap">
                    <div className="container">
                        <div className="site-banner-content">
                            <h1>404 </h1>
                            <h6>Page not found.</h6>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>);
}
export default PageNotFound;
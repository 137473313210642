import React, {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import validator from "validator";
import {decryptData, encryptData} from "../../utils/crypto";
import {toast} from "react-toastify";
import {Settings, SubscribeUser} from "../../services/CommonService";
import {useTranslation} from "react-i18next";
import Utils from "../../utils";

function Footer() {
    const {i18n, t} = useTranslation();
    const {lang} = useParams();
    let errorsObj = {
        email: "",
    };
    const [errors, setErrors] = useState(errorsObj);
    const [email, setEmail] = useState('');
    const [info, setInfo] = useState([]);

    useEffect(() => {
        Settings()
            .then(response => {
                const settingData = decryptData(response.data);
                setInfo(settingData.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    function onSubscribeDetails(e) {
        e.preventDefault();
        let error = false;
        const errorObj = {...errorsObj};

        if (!email) {
            errorObj.email = "Email must be required";
            error = true;
        } else if (!validator.isEmail(email)) {
            errorObj.email = 'Please enter valid email';
            error = true;
        }

        setErrors(errorObj);
        if (error) return;

        let languageId = Utils.getLanguageId(i18n.language);
        let obj = {
            email: email,
        };
        let encryptObj = encryptData(obj);
        SubscribeUser(languageId, encryptObj)
            .then(response => {
                const decryptedSubscribe = decryptData(response.data);
                setEmail('');
                toast.success(decryptedSubscribe.message);
            }).catch(err => {
            if (err.response.status) {
                const decryptedSubscribe = decryptData(err.response.data);
                toast.warn(decryptedSubscribe.message);
            } else {
                toast.error(err);
            }
        });
    }

    return (
        <footer className="site-footer">
            <div className="primary-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="footer-content">
                                <h3 className="footer-title">About</h3>
                                <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their
                                    default model.</p>

                                <form onSubmit={onSubscribeDetails}>
                                    <div className="mb-3">
                                        <input className="form-control" placeholder="Email address"
                                               value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        {errors.email && (
                                            <div className="text-danger form-text">{errors.email}</div>)}
                                    </div>
                                    <button type="submit" className="btn btn-primary">Subscribe Now</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="footer-content">
                                <h3 className="footer-title">Explore</h3>
                                <ul className="footer-menu">
                                    <li><NavLink
                                        to={!lang ? "/support" : `/` + lang + `/support`}>{t('footer_support')}</NavLink>
                                    </li>
                                    <li><NavLink to={!lang ? "/faq" : `/` + lang + `/faq`}>{t('footer_faqs')}</NavLink>
                                    </li>
                                    <li><NavLink to={!lang ? "/hire" : `/` + lang + `/hire`}>{t('footer_wwu')}</NavLink>
                                    </li>
                                    <li><NavLink
                                        to={!lang ? "/privacy-policy" : `/` + lang + `/privacy-policy`}>{t('footer_pp')}</NavLink>
                                    </li>
                                    <li><NavLink
                                        to={!lang ? "/terms-conditions" : `/` + lang + `/terms-conditions`}>{t('footer_tc')}</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            {info.map((item, index) =>
                                <div className="footer-address-card" key={index}>
                                    <div className="footer-logo">
                                        <img src="/images/logo.png" alt="Logo"/>
                                    </div>
                                    <address>{item.address}</address>
                                    <ul className="footer-address-list">
                                        <li><NavLink to="mailto:email@gmail.com">{item.email}</NavLink></li>
                                        <li><NavLink className="disable-link"
                                                     to="tel:8885556666">{item.phone_number}</NavLink>
                                        </li>
                                    </ul>
                                    <ul className="social-link">
                                        <li><NavLink to={item?.twitter_link} target="_blank"><i
                                            className="bi bi-twitter"></i></NavLink>
                                        </li>
                                        <li><NavLink to={item?.facebook_link} target="_blank"><i
                                            className="bi bi-facebook"></i></NavLink></li>
                                        <li><NavLink to={item?.youtube_link} target="_blank"><i
                                            className="bi bi-youtube"></i></NavLink>
                                        </li>
                                        <li><NavLink to={item?.insta_link} target="_blank"><i
                                            className="bi bi-instagram"></i></NavLink>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <div className="secondary-footer">
                <div className="container">
                    <p>© Copyright {new Date().getFullYear()} by Osagian</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

